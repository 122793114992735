<template>
  <div style="overflow:auto">
    <ul class="infinite-list">
      <li v-for="(item,key) in models" class="nav-item">
        <span :class="selected==key?'active':''" @click="deviceClick(key)">{{item.name}}</span>
        <!-- <span style="font-size:12px">{{'('+key+')'}}</span> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      models: {
        "BodyDetect": {
          "name": "人体检测仪",
        },
        "peopleCounter": {
          "name": "人数检测仪",
        },
        "ecoWater": {
          "name": "生态流量系统",
        },
        "ecoWater4G": {
          "name": "生态流量4G",
        },
        "ecoWaterJC": {
          "name": "生态流量JC",
        }
      },
      selected: "",
    }
  },
  methods: {
    deviceClick(key) {
      console.log(key);
      this.selected = key;
      const select = {
        name: this.models[key].name,
        key: key
      }
      this.$emit('send-data', select);

    }
  }
}
</script>

<style>
.infinite-list {
  padding-left: 40px;
}
.nav-item {
  display: block;
  height: 40px;
  color: #444;
  line-height: 40px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;
  transition: 0.15s ease-out;
}
.nav-item :hover {
  cursor: pointer;
  color: #409eff;
}
.nav-item .active {
  color: #409eff;
}
</style>