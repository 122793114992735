<template>
  <div class="container-box">
    <!--顶部-->
    <div class="header">
      <el-row :gutter="0">
        <el-col :span="4">
          <div>
            <span class="title">{{isModelEmpty?selectDevice.name:'选择一种设备'}}</span>
          </div>
        </el-col>
        <el-col :span="2">
          <div>
            <span class="modelTitle">{{selectDevice.key}}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="file" v-loading="loading">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane class="fireware" label="固件下载" name="first">
          <!--固件下载-->
          <el-timeline>
            <template v-for="(item,key) in updateItems">
              <el-timeline-item :timestamp="item.fileVersion" placement="top">
                <el-card>
                  <h4>文件名称：{{item.fileName}} <i v-if="item.new" class="el-icon-s-flag" style="color: red;border-radius: 30px">new</i>
                  </h4>
                  <p>文件大小：{{item.fileSize}}</p>

                  <p>更新内容：{{item.content}}</p>
                  <p>说明：{{item.desc}}</p>
                  <el-row>
                    <el-button v-if="item.download" size="small" round>下载本地</el-button>
                    <el-button size="small" round>文件链接</el-button>
                  </el-row>
                </el-card>
              </el-timeline-item>
            </template>
          </el-timeline>
        </el-tab-pane>
        <el-tab-pane label="文档说明" name="second">
          <!--文档说明-->
          <ul v-for="(item,key) in docList" :key="key" style="overflow:auto" class="doc-list">
            <div class="doc-group">{{item.group}}</div>
            <li v-for="(fileItem,filekey) in item.names" class="doc-item">
              {{fileItem}}
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>

    </div>

  </div>
</template>

<script>
export default {
  props: {
    selectDevice: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      updateItems: [
        {
          time: '2024.07.10.sp1',
          content: '修复了xxxxxx,增加了xxxxxxxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          download: true,
          new: true,
          fileSize: '1.5M',
          desc: "需要设备先上线，再测试功能。测试的设备：YLD123、YXSW12345ABC。账号：123",
          fileType: 'binx',
          fileVersion: 'v2024.07.10.sp1',
        },
        {
          time: '2024.07.09',
          content: '增加了xxxxxxxx功能，可以测试xxxxxxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.09',
        },
        {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        },
        {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        }, {
          time: '2024.07.06',
          content: '修复xxxxxxx',
          fileName: '150M_80G_YLD123.binx',
          fileLink: 'http://xxxxxxxxxxx',
          fileSize: '1.5M',
          fileType: 'binx',
          fileVersion: 'v2024.07.06',
          versionNote: '需测试'
        },
      ],
      loading: false,
      activeName: 'first',
      docList: [
        {
          group: "数据手册",
          names: ['YXSW数据操作手册.pdf', 'YXSW数据操作手册.docx', '其他数据操作手册.pdf']
        },
        {
          group: "用户手册",
          names: ['YXSW数据操作手册.pdf', 'YXSW数据操作手册.docx', '其他数据操作手册.pdf']
        },
      ]

    }
  },
  computed: {
    isModelEmpty() {
      return Object.keys(this.selectDevice).length === 0 ? false : true;
    },
  },
}
</script>

<style lang="scss">
.container-box {
  position: relative;
  overflow: hidden; /* 隐藏父级div的滚动条 */
  height: 90vh;
}

.header {
  position: sticky;
  top: 0;
  .title {
    font-size: 20px;
  }
  .modelTitle {
    line-height: 30px;
  }
}
.file {
  margin-top: 20px;
  margin-right: 20px;
  .fireware {
    max-height: calc(100vh - 200px);
    overflow-y: auto; /* 当内容超出时显示纵向滚动条 */
  }
  .doc-list {
    list-style-type: none;
    .doc-group {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
      color: #409eff;
    }
    .doc-item {
      font-size: 14px;
      padding: 5px;
    }
  }
}

.el-timeline-item {
  padding-right: 20px;
}
</style>