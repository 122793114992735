<template>
  <div class="container">
    <el-container>
      <el-header>
        <i class="el-icon-upload" style="color: #409EFF;margin-right:5px;font-size:26px"></i>
        <span style="color: #409EFF">固件升级</span>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <DeviceList @send-data="handleSelect"></DeviceList>
        </el-aside>
        <el-main>
          <UpdateItem :selectDevice="selected"></UpdateItem>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>

<script>
import DeviceList from './components/deviceList.vue';
import UpdateItem from './components/updateItem.vue';
export default {
  components: {
    DeviceList,
    UpdateItem
  },
  data() {
    return {
      selected: {}
    }
  },
  methods: {
    handleSelect(data) {
      this.selected = data;
    },
  }
}
</script>

<style>
.container {
  width: 1180px;
  padding: 0;
  margin: 0 auto;
}
.el-header,
.el-footer {
  /* background-color: #409eff; */
  color: #303133;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  line-height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}

.el-aside {
  /* background-color: #d3dce6; */
  /* color: #333; */
  text-align: left;
  /* line-height: 200px; */
  height: 93vh;
}

.el-main {
  /* background-color: #e9eef3; */
  color: #333;
  text-align: left;
  height: auto;
  border-left: 1px solid #dcdfe6;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>